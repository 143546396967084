<template>
  <div class="order-container">
    <!-- <van-search 
      v-model="value"
      shape="round"
      placeholder="请输入商户名称/商品名称"
      @search="onSearch"
      @clear="onCancel"
    /> -->
    <div class="orderBg">
      <img
        src="@/assets/orderBg.png"
        alt=""
      />
    </div>

    <div class="orderInfo" style=" position: fixed; top: 0; left: 0; width: 100%;">
      <van-tabs v-model="active" @change="changeList" :ellipsis="false">
        <van-tab v-for="item in tabBarList" :title="item.name" :key="item.code" />
      </van-tabs>

      <div class="orderCard">
        <van-list
          class="message-list"
          v-model="loading"
          :finished="finished"
          finished-text="暂无更多数据"
          @load="initData"
        >
          <van-cell-group
            class="page-card"
            v-for="(item, index) in messageList"
            :key="index"
            @click="goToDetail(item)"
          >
            <van-cell class="card-title">
              <template #title>
                <img src='@/assets/titleBar.png' style="width:5px; margin-right:14px;"/>
                <span> {{ `订单时间：${item.targetDate} ${item.fareName}` }} </span>
              </template>
              <template #right-icon v-if="item.orderStatus">
                <van-tag v-if="item.orderStatus=='已完成'" color="rgba(0, 178, 64, 0.15)" text-color="#00B240" style="border-radius: 5px; padding: 0 8px;"> {{item.orderStatus}} </van-tag>
                <van-tag v-if="item.orderStatus=='已退款/取消'" style="border-radius: 5px; padding: 0 8px;"> {{item.orderStatus}} </van-tag>
                <van-tag v-if="item.orderStatus=='配送中'" color="rgba(255, 63, 107, 0.15)" text-color="#FF3F6B" style="border-radius: 5px; padding: 0 8px;"> {{item.orderStatus}} </van-tag>
              </template>
            </van-cell>
            <van-row class="text-overflow" v-for="(i, index) in item.dishDetailMsgList" :key="index">
              <van-col span="16">
                <van-cell :title="`菜品名称：${i.dishName}`" />
              </van-col>
              <van-col span="8">
                <van-cell :value="`数量：${i.dishNum}`" style="padding: 8px 16px 0 0;" />
              </van-col>
            </van-row>
            <van-row>
              <van-col span="16">
                <van-cell :title="`下单时间：${item.orderTime}`" />
              </van-col>
              <van-col span="8">
                <van-cell :value="`金额：${item.sumPrice}元`" style="padding: 8px 16px 0 0;" />
              </van-col>
            </van-row>
            <van-cell title="" class="card-button">
              <template #right-icon>
                 <van-button
                  round
                  @click.stop="onCancelOrder(item)"
                  v-if="loginInfo && item.orderStatus=='商家接单'"
                  style="margin-left: 10px;"
                  >取消订单</van-button
                >
                <van-button
                  round
                  type="warning"
                  @click.stop="onConfirm(item)"
                  v-if="loginInfo && item.orderStatus=='配送中'"
                  style="margin-left: 10px;"
                  >确认送达</van-button
                >
                <!-- <van-button
                  round
                  color="#07c160"
                  @click.stop="onPay(item)"
                  v-if="item.orderStatus=='待支付'"
                  style="margin-left: 10px;"
                  >去支付</van-button
                > -->
              </template>
            </van-cell>
          </van-cell-group>
        </van-list>
      </div>
    </div>

    <!-- 底部导航按钮 -->
    <div class="page-bottom-nav">
      <van-tabbar
        v-model="activeNav"
        active-color="#000000"
        inactive-color="#767676"
      >
        <van-tabbar-item icon="home-o" @click="goToHome">
          <span>首页</span>
          <template #icon="props">
            <img
              :src="
                props.active
                  ? require('@/assets/mHomeActive.png')
                  : require('@/assets/mHome.png')
              "
            />
          </template>
        </van-tabbar-item>
        <van-tabbar-item icon="search">
          <span>订单</span>
          <template #icon="props">
            <img
              :src="
                props.active
                  ? require('@/assets/mOrderActive.png')
                  : require('@/assets/mOrder.png')
              "
            />
          </template>
        </van-tabbar-item>
        <van-tabbar-item icon="friends-o" @click="goToPersonal" v-if="loginInfo">
          <span>个人中心</span>
          <template #icon="props">
            <img
              :src="
                props.active
                  ? require('@/assets/mPersonalActive.png')
                  : require('@/assets/mPersonal.png')
              "
            />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>

    <van-overlay v-show="showCancel" class="loadingbox">
      <van-loading size="27px" vertical type="spinner" color="#F99502" class="loading" >正在退款，请稍候...</van-loading>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "myOrder",
  data() {
    return {
      // value: "",
      active: 0,
      tabBarList: [
        {
          code: "1",
          name: "全部",
        },
        // {
        //   code: "2",
        //   name: "待支付",
        // },
        {
          code: "4",
          name: "配送中",
        },
        {
          code: "3",
          name: "已完成",
        },
        {
          code: "5",
          name: "退款/取消",
        },
      ],
      messageList: [],
      activeNav: 1,
      loading: false,
      finished: false,
      pageNum: 1,
      pageSize: 10,
      loginInfo: {},
      showCancel: false,
    };
  },
  watch: {
  },
  mounted() {
    this.loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    this.initData();
  },
  methods: {
    initData() {
      if (this.finished) {
        return;
      } else {
        this.loading = true;
      }
      if(this.pageNum == 1){
        this.messageList = []
      }
      // this.messageList = [
      //   {
      //     ordData: "2024-04-30",
      //     ordType: "早餐",
      //     orStatusName: "已完成",
      //     orderDetails: [
      //       {
      //         gname: "菜板",
      //         gnum: "2",
      //       },
      //       {
      //         gname: "锅",
      //         gnum: "1",
      //       }
      //     ],
      //     actualPrice: "100",
      //     orData: "2024-04-24 07:31:01",
      //   },
      //   {
      //     ordData: "2024-04-30",
      //     ordType: "早餐",
      //     orStatusName: "",
      //     orderDetails: [
      //       {
      //         gname: "菜板",
      //         gnum: "2",
      //       },
      //       {
      //         gname: "锅",
      //         gnum: "1",
      //       }
      //     ],
      //     actualPrice: "100",
      //     orData: "2024-04-24 07:31:01",
      //   },
      //   {
      //     ordData: "2024-04-31",
      //     ordType: "午餐",
      //     orStatusName: "配送中",
      //     orderDetails: [
      //       {
      //         gname: "鱼香肉丝",
      //         gnum: "2",
      //       },
      //     ],
      //     actualPrice: "30",
      //     orData: "2024-04-22 08:31:01",
      //   },
      //   {
      //     targetDate: "2024-06-31",
      //     fareName: "午餐",
      //     orderStatus: "待支付",
      //     dishDetailMsgList: [
      //       {
      //         gname: "鱼香肉丝",
      //         gnum: "2",
      //       },
      //     ],
      //     sumPrice: "30",
      //     orData: "2024-06-22 18:31:01",
      //   },

      // ]
      // this.loading = false;
      // this.finished = true;
      const roomInfo = JSON.parse(localStorage.getItem('roomInfo'));
      this.$http
        .getAction("/order/orderQuery", {
          orderStatus: this.active == 0 ? null : this.active,
          openid: this.loginInfo ? this.loginInfo.openid : null,
          hospitalId: (!this.loginInfo&&roomInfo) ? roomInfo.hospitalId : null,
          pageSize: this.pageSize,
          pageNum: this.pageNum,
        })
        .then((res) => {
          if (res.code == 200) {
            this.messageList=this.messageList.concat(res.rows)
            if(res.rows.length < this.pageSize){
              this.finished = true;
            } else{
              this.pageNum++;
            }
          } else {
            this.finished = true;
          }
          this.loading = false;
          console.log(res);
        })
        .catch((error) => {
          this.loading = false;
          this.finished = true;
        });
    },
    // onSearch() {
    //   this.finished = false;
    //   this.pageNum = 1;
    //   this.initData();
    // },
    // onCancel() {
    //   this.finished = false;
    //   this.pageNum = 1;
    //   this.initData();
    // },
    changeList() {
      this.finished = false;
      this.pageNum = 1
      this.initData();
    },
    onConfirm(item) {
      this.$dialog.confirm({
        // title: '标题',
        message: '是否确认收货？',
      })
        .then(() => {
          this.$http
            .postAction("/order/orderOption", {
              orderNo: item.orderNo,
            })
            .then((res)=>{
              if (res.code == 200) {
                this.finished = false;
                this.pageNum = 1;
                this.initData();
              }
            })
        })
      .catch(() => {
        // on cancel
      });
    },
    // onPay(item){
    //   this.$router.push({ path: "/pay" });
    // },
    onCancelOrder(item) {
      this.$dialog.confirm({
        // title: '标题',
        message: '是否取消该订单？',
      })
        .then(() => {
          this.showCancel = true;
          this.$http
            .postAction("/order/orderCancel", {
              orderNo: item.orderNo,
              openid: this.loginInfo ? this.loginInfo.openid : null,
            })
            .then((res)=>{
              if (res.code == 200) {
                this.$http
                  .getAction("/refundQuery", {
                    orderNo: item.orderNo,
                  })
                  .then((response)=>{
                    this.showCancel = false;
                    if(response.data == '退款成功'){
                      this.$toast("退款成功");
                      this.finished = false;
                      this.pageNum = 1;
                      this.initData();
                    // } else if(response.msg == '退款失败'){
                    //   this.$toast.fail("退款失败");
                    } else{
                      this.$toast.fail(response.data);
                    }
                  })
              } else{
                this.showCancel = false;
                this.$toast.fail(res.msg);
              }
            })
            .catch((e) => {
              this.showCancel = false;
              this.$toast.fail(e);
            });
        })
      .catch(() => {
        this.showCancel = false;
        this.$toast.fail(e);
      });
    },
    goToDetail(item) {
      this.$router.push({ path: "/orderDetail",query:{orderNo:item.orderNo}});
    },
    // 点击跳转首页
    goToHome() {
      if(this.loginInfo){
        this.$router.replace({ path: "/home" });
      } else{
        this.$router.replace({ path: "/apkHome" });
      }
    },
    // 点击跳转个人中心页
    goToPersonal() {
      this.$router.replace({ path: "/personalCenter" });
    },
  },
};
</script>

<style lang="less" scoped>
.order-container {
  height: 100vh;
  background: white;
  // background: url('./orderBg.png') no-repeat;
  /deep/ .van-tabs {
    margin: 10px 0 5px;
  }
  /deep/ .van-tab {
    color: #3D3D3D;
  }
  /deep/ .van-tab--active {
    color: #F99502;
  }
  /deep/ .van-tabs__nav{
    background: none;
    padding-bottom: 10px;
  }
  /deep/ .van-tabs__line {
    background-color: #FFC34D;
  }
  .message-list {
    height: calc(100vh - 6.625rem);
    overflow: auto;
  }
  .van-cell::after {
    border: none;
  }
  .page-card {
    margin: 10px 16px 0px 16px !important;
    // padding-bottom: 20px;
    border: 1px solid #D8D8D8;
    // font-weight: bold;
    font-size: 12px;
    .card-title{
      padding: 15px 16px 5px 16px !important;
      .van-cell__title{
        display: flex;
        align-items: center;
      }
    }
    .van-cell {
      color: #3D3D3D;
      padding: 8px 16px 0 35px;
    }
    .card-button {
      margin-top: 10px;
      padding-bottom: 10px;
      border-top: 1px dashed #D8D8D8;
      .van-button{
        padding: 4px 18px;
        font-size: 10px;
        height: auto;
      }
    }
    .text-overflow{
      .van-cell__title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .van-cell__value {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .van-cell__title:hover{
        overflow: visible;
      }
      .van-cell__value:hover{
        overflow: visible;
      }
    }
    .van-cell__value {
      // text-align: left;
      // padding-right: 15px;
      color: #323232;
    }
  }
}
.loadingbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
